import React from 'react'

const Phone = () => {
  return (
    <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.27238 0.43881L4.34837 0.0948098C5.35737 -0.22719 6.43537 0.29381 6.86737 1.31181L7.72738 3.33981C8.10138 4.22281 7.89338 5.26181 7.21338 5.90781L5.31838 7.70581C5.43538 8.78181 5.79738 9.84081 6.40338 10.8828C6.97884 11.8909 7.75075 12.7734 8.67338 13.4778L10.9494 12.7178C11.8114 12.4308 12.7514 12.7618 13.2794 13.5388L14.5124 15.3488C15.1274 16.2528 15.0164 17.4988 14.2534 18.2648L13.4364 19.0858C12.6224 19.9028 11.4594 20.1998 10.3844 19.8638C7.84437 19.0718 5.51138 16.7208 3.38138 12.8108C1.24838 8.89481 0.495375 5.57081 1.12338 2.84281C1.38738 1.69481 2.20438 0.77981 3.27238 0.43881Z" fill="#B57F4F" />
    </svg>
  )
}

export default Phone